import BaseModel from './BaseModel';
import Project from './Project';
import Release from './Release';
import Sprint from './Sprint';
import Goal from './Goal';
import Log from './Log';
import User from './User';
import Commit from './Commit';
import Comment from './Comment';
import Quote from './Quote';
import { AnyObj } from '../types';
import _ from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';
import type { IconType } from '../components/Icon';
import Client from "./Client";
import { Changes } from "../services";

/** Class representing a change. */
class Change extends BaseModel {
    /**
     * Change type constants
     */
    static TYPE_FEATURE  = 'feature';
    static TYPE_FIX      = 'fix';
    static TYPE_REFACTOR = 'refactor';
    static TYPE_UI       = 'ui';
    static TYPE_I18N     = 'i18n';
    static TYPE_TEST     = 'test';
    static TYPE_DOC      = 'doc';
    static TYPE_LINT     = 'lint';
    static TYPE_CHORE    = 'chore';
    static TYPE_BUILD    = 'build';
    static TYPE_DEPS     = 'dependencies';
    static TYPE_RELEASE  = 'release';
    static TYPE_IGNORED  = 'ignored';
    static TYPE_NONE     = 'none';  // to be used fof "non dev" tasks

    /**
     * Change progress status constants
     */
    static STATUS_PENDING_TRIAGE        = 'pendingTriage';
    static STATUS_PENDING_ESTIMATE      = 'pendingEstimate';
    static STATUS_ESTIMATED             = 'estimated';
    static STATUS_PENDING_PLANIFICATION = 'pendingPlanification';
    static STATUS_PLANNED               = 'planned';
    static STATUS_ASSIGNED              = 'assigned';
    static STATUS_IN_PROGRESS           = 'inProgress';
    static STATUS_TEAM_REVIEW           = 'teamReview';
    static STATUS_CLIENT_REVIEW         = 'clientReview';
    static STATUS_PENDING_DEPLOYMENT    = 'pendingDeployment';
    static STATUS_DEPLOYED              = 'deployed';
    static STATUS_FINISHED              = 'finished';

    /**
     * Billing methods constants
     */
    static BILLING_METHOD_FIXED  = 'fixed';
    static BILLING_METHOD_HOURLY = 'hourly';

    /**
     * Importance constants
     */
    static IMPORTANCE_VERY_HIGH = 5;
    static IMPORTANCE_HIGH      = 4;
    static IMPORTANCE_NORMAL    = 3;
    static IMPORTANCE_LOW       = 2;
    static IMPORTANCE_VERY_LOW  = 1;
    static IMPORTANCE_ABANDONED = 0;

    _id: string;
    author?: AnyObj;
    createdAt?: string;
    slug: number;
    release: Release;
    release_notes: {
        title: AnyObj;
        description: AnyObj;
        image?: string;
    };
    sprints: Sprint[];
    goals: Goal[];
    assignee?: User;
    type: string;
    developer_notes?: string;
    logs: Log[];
    commits: Commit[];
    comments: Comment[];
    project: Project;
    project_name: string;
    title: AnyObj;
    description: AnyObj;
    ticket_number?: string;
    ticket_view_url?: string;
    ticket_reply_url?: string;
    ticket_close_url?: string;
    nbAttachments: number;
    nbComments: number;
    deadline?: Moment;
    estimable: boolean;
    estimate: number;
    importance: number;
    priority: number;
    quote?: Quote;
    billable: boolean;
    billingMethod: string;
    progress: {
        status: string;
        estimatedAt?: Moment;
        plannedAt?: Moment;
        assignedAt?: Moment;
        startedProgressAt?: Moment;
        startedTeamReviewAt?: Moment;
        startedClientReviewAt?: Moment;
        clientReviewAcceptedAt?: Moment;
        deployedAt?: Moment;
        finishedAt?: Moment;
    };
    client?: Client;
    issue?: {
        id: string;
        link: string;
        source: string;
    }

    constructor(properties: AnyObj) {
        super({});

        this._id             = properties._id;
        this.author          = new User(properties.author?._id ? properties.author : { _id: properties.author });
        this.createdAt       = properties.createdAt;
        this.slug            = properties.slug;
        this.type            = properties.type;
        this.developer_notes = properties.developer_notes;
        this.project         = properties.project;
        if (_.isObject(this.project))
            this.project = new Project(this.project);

        this.project_name  = properties.project_name;
        this.title         = properties.title;
        this.description   = properties.description;
        this.ticket_number = properties.ticket_number;
        this.nbAttachments = properties.nbAttachments || 0;
        this.nbComments    = properties.nbComments || 0;

        this.release = properties.release;
        if (properties.release &&
            !properties.release._id &&
            properties.release.length > 0
        ) {
            this.release = new Release({
                _id: properties.release
            });
        }

        this.sprints = (properties.sprints || []).map((s: AnyObj) => new Sprint(s));
        this.goals   = (properties.goals || []).map((g: AnyObj) => new Goal(_.isObject(g) ? g : {_id: g}));

        if (properties.assignee)
            this.assignee = new User(properties.assignee);

        if (properties.deadline)
            this.deadline = moment(properties.deadline);

        if (properties.quote)
            this.quote = new Quote(properties.quote);

        if (properties.issue)
            this.issue = properties.issue;

        this.estimable     = ('estimable' in properties ? !!properties.estimable : true);
        this.estimate      = properties.estimate || 0;
        this.importance    = properties.importance;
        this.priority      = properties.priority;
        this.billable      = !!properties.billable;
        this.billingMethod = properties.billingMethod;

        this.release_notes = properties.release_notes || {};
        if (!this.release_notes?.title)
            this.release_notes.title = {};
        if (!this.release_notes?.description)
            this.release_notes.description = {};

        this.progress = properties.progress;
        if (this.progress?.estimatedAt)
            this.progress.estimatedAt = moment(this.progress.estimatedAt);
        if (this.progress?.plannedAt)
            this.progress.plannedAt = moment(this.progress.plannedAt);
        if (this.progress?.assignedAt)
            this.progress.assignedAt = moment(this.progress.assignedAt);
        if (this.progress?.startedProgressAt)
            this.progress.startedProgressAt = moment(this.progress.startedProgressAt);
        if (this.progress?.startedTeamReviewAt)
            this.progress.startedTeamReviewAt = moment(this.progress.startedTeamReviewAt);
        if (this.progress?.startedClientReviewAt)
            this.progress.startedClientReviewAt = moment(this.progress.startedClientReviewAt);
        if (this.progress?.clientReviewAcceptedAt)
            this.progress.clientReviewAcceptedAt = moment(this.progress.clientReviewAcceptedAt);
        if (this.progress?.deployedAt)
            this.progress.deployedAt = moment(this.progress.deployedAt);
        if (this.progress?.finishedAt)
            this.progress.finishedAt = moment(this.progress.finishedAt);

        this.comments = properties.comments?.map((comment: AnyObj) => new Comment(comment));

        // virtuals
        this.logs    = (properties.logs || []).map((log: AnyObj) => new Log(log));
        this.commits = properties.commits?.map((commit: AnyObj) => new Commit(commit));
        this.client  = properties.client;
        if (_.isObject(this.client))
            this.client = new Client(this.client);
        if (properties.ticket_view_url)
            this.ticket_view_url = properties.ticket_view_url;
        if (properties.ticket_reply_url)
            this.ticket_reply_url = properties.ticket_reply_url;
        if (properties.ticket_close_url)
            this.ticket_close_url = properties.ticket_close_url;
    }

    static allStatus() {
        return [
            Change.STATUS_PENDING_TRIAGE,
            Change.STATUS_PENDING_ESTIMATE,
            Change.STATUS_ESTIMATED,
            Change.STATUS_PENDING_PLANIFICATION,
            Change.STATUS_PLANNED,
            Change.STATUS_ASSIGNED,
            Change.STATUS_IN_PROGRESS,
            Change.STATUS_TEAM_REVIEW,
            Change.STATUS_CLIENT_REVIEW,
            Change.STATUS_PENDING_DEPLOYMENT,
            Change.STATUS_DEPLOYED,
            Change.STATUS_FINISHED
        ];
    }

    statusIndex(status?: string) {
        return Change.allStatus().indexOf(status || this.progress?.status);
    }

    localizedTitle(lang: string) : string | null {
        if (!this.title)
            return null;

        return (lang in this.title && this.title[lang]) ? this.title[lang] : this.title[Object.keys(this.title)[0]];
    }

    localizedDescription(lang: string) : string | null {
        if (!this.description)
            return null;

        return (lang in this.description) ? this.description[lang] : this.description[Object.keys(this.description)[0]];
    }

    localizedReleaseTitle(lang: string) : string | null {
        if (!this.release_notes?.title)
            return null;

        return (lang in this.release_notes.title && this.release_notes.title[lang]) ?
            this.release_notes.title[lang] :
            this.release_notes.title[Object.keys(this.release_notes.title)[0]];
    }

    localizedReleaseDescription(lang: string) : string | null {
        if (!this.release_notes?.description)
            return null;

        return (lang in this.release_notes.description) ?
            this.release_notes.description[lang] :
            this.release_notes.description[Object.keys(this.release_notes.description)[0]];
    }

    /**
     * Check if this change is considered as started
     *
     * @param {Moment} at
     * @return {Boolean}
     */
    isStarted(at?: Moment): boolean {
        const startedStatus = [
            Change.STATUS_IN_PROGRESS,
            Change.STATUS_TEAM_REVIEW,
            Change.STATUS_CLIENT_REVIEW,
            Change.STATUS_PENDING_DEPLOYMENT,
            Change.STATUS_DEPLOYED,
            Change.STATUS_FINISHED
        ];

        if (!startedStatus.includes(this.progress?.status))
            return false;

        if (!at)
            return true;

        if (!this.progress.startedProgressAt)
            return false;

        return this.progress.startedProgressAt.isBefore(at);
    }

    /**
     * Check if this change is considered as finished, i.e iif
     * it's in one of the three following statuses:
     * - STATUS_PENDING_DEPLOYMENT
     * - STATUS_DEPLOYED
     * - STATUS_FINISHED
     *
     * @param {Moment} at
     * @return {Boolean}
     */
    isFinished(at?: Moment): boolean {
        const doneStatus = [
            Change.STATUS_PENDING_DEPLOYMENT,
            Change.STATUS_DEPLOYED,
            Change.STATUS_FINISHED
        ];

        if (!doneStatus.includes(this.progress?.status))
            return false;

        if (!at)
            return true;

        // for non dev changes, we do not have the review step,
        // so let's check the finishedAt property
        if (!this.isDev()) {
            if (!this.progress.finishedAt)
                return false;

            return this.progress.finishedAt.isBefore(at);
        }

        if (!this.progress.clientReviewAcceptedAt)
            return false;

        return this.progress.clientReviewAcceptedAt.isBefore(at);
    }

    /**
     * Check if we have completed the work needed for this change,
     * i.e iif we are in a status "greater" than STATUS_IN_PROGRESS.
     *
     * Used in the sprints.
     *
     * @param {Moment} at
     * @return {Boolean}
     */
    isWorkCompleted(at?: Moment): boolean {
        if (this.statusIndex() <= this.statusIndex(Change.STATUS_IN_PROGRESS))
            return false;

        if (!at)
            return true;

        // for non dev changes, we do not have the review step,
        // so let's check the finishedAt property
        if (!this.isDev()) {
            if (!this.progress.finishedAt)
                return false;

            return this.progress.finishedAt.isBefore(at);
        }

        if (!this.progress.startedTeamReviewAt)
            return false;

        return this.progress.startedTeamReviewAt.isBefore(at);
    }

    /**
     * Check if this change is a development task
     *
     * @return {Boolean}
     */
    isDev(): boolean {
        return this.type !== Change.TYPE_NONE;
    }

    /**
     * Check if this change can be time tracked.
     * If by is provided, we check if this change can be
     * time tracked by the provided user.
     *
     * @param {User} by, optional
     * @return {Boolean}
     */
    isTimeTrackable(by?: User): boolean {
        if (!this.isStarted() || this.isFinished())
            return false;

        if (!by)
            return true;

        return this.isInReview(/*onlyByTeam*/true) || this.assignee?._id === by._id;
    }

    /**
     * Check whether this modification is under review (dev or customer)
     *
     * i@param {Boolean} onlyByTeam: true to check if this change is in review
     *                               by the team (ignore client review)
     * @return {Boolean}
     */
    isInReview(onlyByTeam?: boolean): boolean {
        return (
            (!onlyByTeam && this.progress?.status === Change.STATUS_CLIENT_REVIEW) ||
            this.progress?.status === Change.STATUS_TEAM_REVIEW
        );
    }

    /**
     * Check if this change matches a search string
     *
     * @param {String} search
     * @return {Boolean}
     */
    match(search: string) : boolean {
        let result: boolean = false;

        const sanitized = Change.sanitize(search);

        Object.keys(this.title).forEach((key: string) => {
            const sanitizedTitle = Change.sanitize(this.title[key]);
            result = result || !!sanitizedTitle.match(new RegExp(sanitized, 'gi'));
        })

        if (!result)
            result = !!this.slugName().match(new RegExp(sanitized, 'gi'));

        return result;
    }

    /**
     * Sanitize a string by replacing diacritics
     *
     * @param {String} str
     * @return {String} sanitized string
     */
    static sanitize(str: string) : string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    /**
     * Get the date when this change finnished
     *
     * @return {Moment | undefined}
     */
    finishedAt(): Moment | undefined {
        if (!this.isFinished())
            return undefined;

        if (this.isDev())
            return this.progress.clientReviewAcceptedAt;

        return this.progress.finishedAt;
    }

    /**
     * Check if this change can be planned and assigned
     * - if it's not billable => true
     * - if it's billable
     *   - if it's billed hourly => true
     *   - if !needsAdvance and status is AcceptedQuote => true
     *   - if needsAdvance and status is PaidAdvance => true
     *   - else => false
     *
     * @return {Boolean}
     */
    isPlannable(): boolean {
        if (!this.billable)
            return true;

        if (this.isBilledHourly())
            return true;

        if (!this.quote)
            return false;

        const allowedStatus = [
            Quote.STATUS_ADVANCE_PAID,
            Quote.STATUS_BILLED,
            Quote.STATUS_PAID
        ];

        if (!this.quote.needsAdvance)
            allowedStatus.push(Quote.STATUS_ACCEPTED);

        return allowedStatus.includes(this.quote.status);
    }

    /**
     * Check if this change can be put into a sprint,
     * i.e if it's not finished and not already in a
     * not finished sprint
     *
     * @return {Boolean}
     */
    isSprintable(): boolean {
        if (this.isFinished())
            return false;

        return !this.sprints?.some((s: Sprint) => !s.isFinished());
    }

    /**
     * Check if this change can be put into a quote
     *
     * @return {Boolean}
     */
    isQuoteable(): boolean {
        return !this.quote;
    }

    /**
     * Check if this change is billed hourly
     *
     * @return {Boolean}
     */
    isBilledHourly(): boolean {
        return this.billingMethod === Change.BILLING_METHOD_HOURLY;
    }

    /**
     * Check if this change is a feature
     *
     * @return {Boolean}
     */
    isFeature() : boolean {
        return this.type === Change.TYPE_FEATURE;
    }

    /**
     * Check if this change is a bug fix
     *
     * @return {Boolean}
     */
    isBugFix() : boolean {
        return this.type === Change.TYPE_FIX;
    }

    slugName() : string {
        if (!this.isDev())
            return `T${this.slug}`;

        if (this.isFeature())
            return `F${this.slug}`;

        if (this.isBugFix())
            return `B${this.slug}`;

        return `C${this.slug}`;
    }

    formattedDate(){
        if (this.createdAt){
            return moment(this.createdAt).format("DD.MM.YYYY");
        }
    }

    /**
     * Get a value betwwen 0 and 100 representing the
     * progression of this change,
     *
     * @return {Number}
     */
    progression() : number {
        if (this.isFinished()) // 100%
            return 100;

        if (this.isInReview()) // 80%
            return 80;

        if (this.isStarted()) // 20%
            return 20;

        return 0;
    }

    iconName() : IconType { return Change.iconName(this.type); }

    /**
     * Get the list of possible changes types
     * @return {String[]}
     */
    static types() : string[] {
        return [
            Change.TYPE_FEATURE,
            Change.TYPE_FIX,
            Change.TYPE_UI,
            Change.TYPE_I18N,
            Change.TYPE_LINT,
            Change.TYPE_TEST,
            Change.TYPE_BUILD,
            Change.TYPE_REFACTOR,
            Change.TYPE_DOC,
            Change.TYPE_CHORE,
            Change.TYPE_DEPS,
            Change.TYPE_RELEASE,
            Change.TYPE_IGNORED,
            Change.TYPE_NONE
        ];
    }

    /**
     * Get the icon to represent the provided change type
     * @return {String}
     */
    static iconName(type: string) : IconType {
        switch (type) {
            case Change.TYPE_FEATURE:
                return 'feature';
            case Change.TYPE_FIX:
                return 'bug';
            case Change.TYPE_REFACTOR:
                return 'code';
            case Change.TYPE_UI:
                return 'ui';
            case Change.TYPE_I18N:
                return 'globe';
            case Change.TYPE_TEST:
                return 'test';
            case Change.TYPE_DOC:
                return 'comment';
            case Change.TYPE_LINT:
                return 'lint';
            case Change.TYPE_BUILD:
                return 'build';
            case Change.TYPE_RELEASE:
                return 'release';
            case Change.TYPE_CHORE:
                return 'chore';
            case Change.TYPE_NONE:
                return 'task';
            case Change.TYPE_IGNORED:
            default:
                return 'merge';
        }
    }

    color() : string { return Change.color(this.type); }

    /**
     * Get the color to represent the provided change type
     * @return {String}
     */
    static color(type: string) : string {
        switch (type) {
            case Change.TYPE_FEATURE:
                return 'purple';
            case Change.TYPE_FIX:
                return 'failure';
            case Change.TYPE_REFACTOR:
            case Change.TYPE_UI:
            case Change.TYPE_I18N:
            case Change.TYPE_DOC:
            case Change.TYPE_LINT:
                return 'indigo';
            case Change.TYPE_TEST:
                return 'info';
            case Change.TYPE_BUILD:
            case Change.TYPE_RELEASE:
                return 'success';
            case Change.TYPE_NONE:
                return 'lime';
            case Change.TYPE_CHORE:
            case Change.TYPE_IGNORED:
            default:
                return 'gray';
        }
    }

    issueIconName() : IconType {
        switch (this.issue?.source) {
            case 'sentry':
                return this.issue.source;
            default:
                return 'bug';
        }
    }

    /**
     * Get the list of changes sortable properties.
     * This is used by the boards smart columns.
     *
     * @param {String} lang
     *
     * @return {Object[]} An array of objects with the following properties:
     *                    - property
     *                    - type
     *                    - label
     */
    static getSortableProperties(lang: string) {
        const properties = [
            [ 'slug' ],
            [ 'importance' ],
            [ 'priority' ],
            [ 'createdAt' ],
            [ 'deadline' ],
            [ 'estimate' ],
            [ 'type' ],
            [ `title.${lang}`, 'title' ],
            [ 'progress.status' ],
            [ 'progress.plannedAt' ],
            [ 'progress.assignedAt' ],
            [ 'progress.startedProgressAt' ],
            [ 'progress.startedTeamReviewAt' ],
            [ 'progress.startedClientReviewAt' ],
            [ 'progress.clientReviewAcceptedAt' ],
            [ 'progress.deployedAt' ],
            [ 'progress.finishedAt' ],
        ];

        return properties.map((props: any) => ({
            property: props[0],
            type: Change.getPropertyType(props[1] ?? props[0]),
            label: `changes.sort_properties.${props[1] ?? props[0]}`
        }));
    }

    /**
     * Get the list of changes filterable properties.
     * This is used by the boards smart columns.
     *
     * @param {String} lang
     *
     * @return {Object[]} An array of objects with the following properties:
     *                    - property
     *                    - type
     *                    - label
     */
    static getFilterableProperties(lang: string) {
        const properties = [
            [ 'project' ],
            [ 'client' ],
            [ 'assignee' ],
            [ 'slug' ],
            [ 'importance' ],
            [ 'priority' ],
            [ 'createdAt' ],
            [ 'deadline' ],
            [ 'estimate' ],
            [ 'type' ],
            [ `title.${lang}`, 'title' ],
            [ 'progress.status' ],
            [ 'progress.plannedAt' ],
            [ 'progress.assignedAt' ],
            [ 'progress.startedProgressAt' ],
            [ 'progress.startedTeamReviewAt' ],
            [ 'progress.startedClientReviewAt' ],
            [ 'progress.clientReviewAcceptedAt' ],
            [ 'progress.deployedAt' ],
            [ 'progress.finishedAt' ],
        ];

        return properties.map((props: any) => ({
            property: props[0],
            type: Change.getPropertyType(props[1] ?? props[0]),
            label: `changes.sort_properties.${props[1] ?? props[0]}`
        }));
    }

    /**
     * Get a property type.
     *
     * @param {String} property
     *
     * @return {String}
     */
    static getPropertyType(property: string) {
        switch (property) {
            case 'slug':
            case 'estimate':
            case 'importance':
            case 'priority':
                return 'number';

            case 'project':
            case 'client':
            case 'assignee':
            case 'type':
            case 'title':
            case 'progress.status':
                return 'string';

            case 'createdAt':
            case 'deadline':
            case 'progress.plannedAt':
            case 'progress.assignedAt':
            case 'progress.startedProgressAt':
            case 'progress.startedTeamReviewAt':
            case 'progress.startedClientReviewAt':
            case 'progress.clientReviewAcceptedAt':
            case 'progress.deployedAt':
            case 'progress.finishedAt':
                return 'date';
        }
    }

    /**
     * Get the list of smart columns filters operators.
     *
     * @return {Object[]} An array of objects with the following properties:
     *                    - op
     *                    - type
     *                    - label
     */
    static getFilterOperators(property?: string) {
        let operators = [
            ['$eq',          'scalar' ],
            ['$ne',          'scalar' ],
            ['$lt',          'scalar' ],
            ['$lte',         'scalar' ],
            ['$gt',          'scalar' ],
            ['$gte',         'scalar' ],
            ['$in',          'scalar' ],
            ['!$in',         'scalar' ],
            ['ISODate:$lt',  'date', '$lt' ],
            ['ISODate:$lte', 'date', '$lte' ],
            ['ISODate:$eq',  'date', '$eq' ],
            ['ISODate:$gt',  'date', '$gt' ],
            ['ISODate:$gte', 'date', '$gte' ],
        ];

        if (property) {
            const type = Change.getPropertyType(property);

            if (!['project', 'progress.status', 'type'].includes(property)) {
                operators = operators.filter((props: any) => {
                    return props[0] !== '$in' && props[0] !== '!$in';
                })
            }

            operators = operators.filter((props: any) => {
                if (type  === 'date')
                    return props[1] === 'date';

                return props[1] !== 'date';
            });
        }

        return operators.map((props: any) => ({
            property: props[0],
            type: props[1],
            label: `projects.column_rules.operators.${props[2] ?? props[0]}`
        }));
    }

    /**
     * Check if an operator supports multiple values
     *
     * @param {String} op
     *
     * @return {Boolean}
     */
    static isMultipleOperator(op: string) {
        return ['$in','!$in'].includes(op);
    }

    /**
     * Get the date possible values for smart filters
     *
     * @return {String[]}
     */
    static getDateFilterValues() {
        return [
            '-1:day',
            '-2:day',
            '-3:day',
            '-4:day',
            '-5:day',
            '-6:day',
            '-1:week',
            '-2:week',
            '-3:week',
            '-1:month',
            '-2:month',
            '-3:month',
            '-6:month',
            '-1:year',
            '1:day',
            '2:day',
            '3:day',
            '4:day',
            '5:day',
            '6:day',
            '1:week',
            '2:week',
            '3:week',
            '1:month',
            '2:month',
            '3:month',
            '6:month',
            '1:year'
        ];
    }

    /**
     * Get the possible values for the importance property
     *
     * @return {Number[]}
     */
    static getImportanceValues() {
        return [
            Change.IMPORTANCE_VERY_HIGH,
            Change.IMPORTANCE_HIGH,
            Change.IMPORTANCE_NORMAL,
            Change.IMPORTANCE_LOW,
            Change.IMPORTANCE_VERY_LOW,
            Change.IMPORTANCE_ABANDONED,
        ];
    }
    /**
     * Get this release note image url
     *
     * @return {String}
     */
    getReleaseImageUrl(size: number = 80, noCache: boolean = false) {
        if (this.release_notes?.image)
            return [
                Changes.baseUrl,
                Changes.entryPoint,
                this._id,
                'releaseImage'
            ].join('/') + (noCache ? '?t=' + Date.now() : '');

        return null;
    }
}

export default Change;
