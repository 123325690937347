import React, { useEffect } from 'react';
import './App.css';
import { useAppSelector, useAppDispatch, RootState } from '../../redux';
import { getLastConnectedUser, loadSetting } from '../../redux';
import { AuthVerify, Mercure } from '../../components';
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { Login } from '../Login';
import { Config } from '../../config';
import Topbar from './Topbar';

import { i18n } from '../../locales';
import moment from 'moment'
import 'moment/locale/fr';
import 'moment/locale/de';
moment.locale(i18n.language);

function App() {
    const { user, isAuthenticating } = useAppSelector((state : RootState) => state.auth);

    const dispatch     = useAppDispatch();
    const navigate     = useNavigate();
    const { pathname } = useLocation();
    const redirectTo   = localStorage.getItem('redirect-to');

    useEffect(() => {
        dispatch(getLastConnectedUser());
    }, [dispatch]);

    useEffect(() => {
        if (user?._id)
            dispatch(loadSetting('default'));
    }, [user]);

    if (isAuthenticating) {
        return <h3>...</h3>;
    }

    if (!user || !('_id' in user)) {
        if (pathname !== '/')
            localStorage.setItem('redirect-to', pathname);
        return <Login />;
    }

    if (redirectTo) {
        localStorage.removeItem('redirect-to');
        navigate(redirectTo);
    }

    return (
        <div className="App h-screen">
            <Topbar />
            <AuthVerify />
            <Mercure hub={Config.mercure_hub} topics={['*']} />
            <div className="flex">
                <div className="w-full">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default App;
