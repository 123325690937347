import BaseModel from './BaseModel';

/** Class representing a Sentry project. */
class SentryProject extends BaseModel {
    id: string;
    name: string;
    slug: string;
    status: string;

    constructor(properties: AnyObj) {
        super({});

        this.id     = properties.id;
        this.name   = properties.name;
        this.slug   = properties.slug;
        this.status = properties.status;
    }
}

export default SentryProject;
