import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { loadChange, loadChanges, deleteChange, resetChanges } from '../../redux';
import { Project, Change } from '../../models';
import { Button, Icon, ChangeModal, ChangesList } from '..';

type MyChangesFilter = {
    project?: Project;
    planned?: boolean;
    unplanned?: boolean;
}
export type { MyChangesFilter };

interface MyChangesProps {
    withHeader?: boolean;
}

function MyChanges(props: MyChangesProps) {
    const { withHeader } = props;

    const [open, setOpen]                       = useState(false);
    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { user }            = useAppSelector((state: RootState) => state.auth);
    const { changes, change } = useAppSelector((state: RootState) => state.changes);

    useEffect(() => {
        if (user) {
            dispatch(loadChanges({
                'assignee': user._id,
                'notfinished': true
            }));
        }
    }, [user, dispatch]);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const content = (
        <div className="grid grid-cols-1 gap-1">
            <ChangesList 
                changes={changes?.sort((a: Change, b: Change) => {
                    if (a.statusIndex() === b.statusIndex()) {
                        return b.priority - a.priority; // Inverse the order of priority
                    }
                    return a.statusIndex() - b.statusIndex();
                }) || []}
                withHeader
                hideUser
                hideStatut
            />
        </div>
    );

    const onRemoveCard = (change: AnyObj) => {
        dispatch(deleteChange(change._id, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(loadChanges({
                'assignee': user._id,
                'notfinished': true
            }));
        }));
    };

    if (!withHeader)
        return content;

    return (
        <>
            <div className='p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md'>
                <div className="flex flex-col md:flex-row pb-2 md:pb-0 items-center justify-between">
                    <h3 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                        <Icon type="commit" size={7} className="inline"/>&nbsp;{t('dashboard.my_tasks')}
                    </h3>
                    <div>
                        <Button
                            onClick={() => setOpen(!open)}
                            icon={open ? 'up' : 'down'}
                            iconSize={7}
                            iconColor="black"
                            title=""
                            color="none"
                        />
                    </div>
                </div>
                {open && (
                    <div className="py-3 overflow-x-auto border-t border-gray-300">
                        {content}
                    </div>
                )}

                {change && change._id === currentChangeId && change.project?.name && (
                    <ChangeModal
                        project={change.project}
                        change={change}
                        show={true}
                        showProjectName={true}
                        onClose={() => selectChange()}
                        onRemove={onRemoveCard}
                    />
                )}
            </div>
        </>
    );
}

export default MyChanges;
