import RestClient from './RestClient';
import { SentryProject } from '../models';

class Sentry extends RestClient {
    constructor() {
        super({
            // rest config
            model: SentryProject,
            entryPoint: 'plugins/sentry/sentryProjects',
            sortBy: 'name',
            id_field: 'id',
            // redux config
            resource: 'sentryProject',
            resources: 'sentryProjects',
        });
    }
}

const SettingsApi = new Sentry();

export default SettingsApi;
