import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { RootState, useAppSelector, useAppDispatch, loadChange, deleteChange, resetChanges, getReviewChanges } from '../../redux';
import { useTranslation } from 'react-i18next';
import { Change, Log } from '../../models';
import { ChangeListItem, Icon, ChangeModal } from '../../components';


function ChangesTeamReview() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();

    const { change }        = useAppSelector(state => state.changes);
    const { reviewChanges } = useAppSelector((state: RootState) => state.changes);

    useEffect(() => {
        dispatch(getReviewChanges({}));
    }, [dispatch]);

    const onSelect = (change: Change) => {
        const logTeamReview = change.logs.filter((log) => log.type === Log.TYPE_CHANGE_TEAM_REVIEW_STARTED);
        if (logTeamReview[0]?.context?.url) {
            window.open(logTeamReview[0].context.url, '_blank');
        }
    };

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const onRemoveCard = (change: AnyObj) => {
        dispatch(deleteChange(change._id, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(getReviewChanges({}));
        }));
    };

    return (
        <div className="ChangesTeamReview">
            {reviewChanges && reviewChanges.length > 0 &&
                <div className="p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md">
                    <div className="pt-3 pb-2 flex items-end justify-between border-b border-gray-300">
                        <h3 className="flex items-center text-2xl font-bold tracking-tight text-gray-900">
                            <Icon type="eye" size={7} />&nbsp;{t('sprints.merge_requests.team_review')}
                            {reviewChanges.length > 0 && <span>&nbsp;({reviewChanges.length})</span>}
                        </h3>
                        <Link className="text-xs" to="sprints/mergerequests" >
                            {t('common.see_all')}
                            <Icon type="right" className="inline mr-1" />
                        </Link>
                    </div>
                    {reviewChanges.sort((a: Change, b: Change) => b.priority - a.priority).map((c: Change) => (
                        <div key={c._id} onClick={() => selectChange(c)}>
                            <ChangeListItem change={c} className={"bg-blue-50 border-blue-200 my-1"} onSelect={onSelect} showProject/>
                        </div>
                    ))}
                </div>
            }

            {change && change._id === currentChangeId && change.project?.name && (
                <ChangeModal
                    project={change.project}
                    change={change}
                    show={true}
                    showProjectName={true}
                    onClose={() => selectChange()}
                    onRemove={onRemoveCard}
                />
            )}
        </div>
    );
}

export default ChangesTeamReview;
