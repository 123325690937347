import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { RootState, useAppSelector, useAppDispatch, loadChange, resetChanges, deleteChange, getTriageChanges } from '../../redux';
import { useTranslation } from 'react-i18next';
import { Change } from '../../models';
import { ChangeListItem, Icon, ChangeModal } from '../../components';


function TriageChanges() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();

    const { change }        = useAppSelector(state => state.changes);
    const { triageChanges } = useAppSelector((state: RootState) => state.changes);

    useEffect(() => {
        dispatch(getTriageChanges({}));
    }, [dispatch]);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const onRemoveCard = (change: AnyObj) => {
        dispatch(deleteChange(change._id, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(getTriageChanges({}));
        }));
    };

    if (!triageChanges?.length)
        return null;

    return (
        <div className="TriageChanges">
            {triageChanges?.length > 0 &&
                <div className="p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md">
                    <div className="pt-3 pb-2 flex items-end justify-between border-b border-gray-300">
                        <h3 className="flex items-center text-2xl font-bold tracking-tight text-gray-900">
                            <Icon type="bug" size={7} />&nbsp;{t('changes.bug_submissions')}
                            {triageChanges.length > 0 && <span>&nbsp;({triageChanges.length})</span>}
                        </h3>
                    </div>
                    {triageChanges.sort((a: Change, b: Change) => b.slug - a.slug).map((c: Change) => (
                        <div key={c._id} onClick={() => selectChange(c)}>
                            <ChangeListItem change={c} className={"bg-red-50 border-red-200 my-1"} showProject/>
                        </div>
                    ))}
                </div>
            }

            {change && change._id === currentChangeId && change.project?.name && (
                <ChangeModal
                    project={change.project}
                    change={change}
                    show={true}
                    showProjectName={true}
                    onClose={() => selectChange()}
                    onRemove={onRemoveCard}
                />
            )}
        </div>
    );
}

export default TriageChanges;
