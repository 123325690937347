import React, { useState } from 'react';
import { RootState, useAppSelector, useAppDispatch, loadChange, deleteChange, resetChanges } from '../../redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { Change } from '../../models';
import { ChangeListItem } from '../ChangeListItem';
import { ChangeModal } from '../ChangeModal';

const MyOpenTasksInPreviousSprints = () => {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { changes } = useAppSelector((state: RootState) => state.changes);
    const { change }  = useAppSelector(state => state.changes);
    const { current } = useAppSelector(state => state.sprints);

    const [oldTasks, setOldTasks]               = useState([]);
    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();

    if (current || changes?.length > 0) {
        let myTasks = changes?.filter((change: Change) => {
            return (
                change.sprints.length > 0 &&
                !change.sprints.filter((sprint) => sprint._id === current?._id)?.length &&
                (change.progress.status !== Change.STATUS_CLIENT_REVIEW && change.progress.status !== Change.STATUS_TEAM_REVIEW)
            );
        });
        if (myTasks.length !== oldTasks.length) {
            setOldTasks(myTasks);
        }
    }

    if (oldTasks?.length === 0) {
        return null;
    }

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const onRemoveCard = (change: AnyObj) => {
        dispatch(deleteChange(change._id, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            selectChange();
        }));
    };

    return (
        <div className="MyOpenTasksInPreviousSprints">
            <div className="p-1 px-3 bg-orange-100 dark:bg-black rounded-lg drop-shadow-md">
                <div className="pt-3 pb-2 mb-2 items-center justify-between border-b border-gray-300">
                    <h3 className="flex items-center text-2xl font-bold tracking-tight text-gray-900">
                        <Icon type="todo" size={7} />&nbsp;{t('dashboard.my_open_tasks_in_previous_sprints')}
                        {oldTasks.length > 0 && <span>&nbsp;({oldTasks.length})</span>}
                    </h3>
                </div>
                <div className="overflow-y-auto max-h-72 grid grid-cols-1 lg:grid-cols-2 gap-1">
                    {oldTasks.sort((a: Change, b: Change) => b.priority - a.priority).map((c: Change) => (
                        <div className="col-span-1" key={c._id} onClick={() => selectChange(c)}>
                            <ChangeListItem
                                change={c}
                                showProject
                            />
                        </div>
                    ))}
                </div>
            </div>

            {change && change._id === currentChangeId && change.project?.name && (
                <ChangeModal
                    project={change.project}
                    change={change}
                    show={true}
                    showProjectName={true}
                    onClose={() => selectChange()}
                    onRemove={onRemoveCard}
                />
            )}
        </div>
    )
}

export default MyOpenTasksInPreviousSprints;
