import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Reminder, Change } from '../../models';
import { Badge, Dropdown} from 'flowbite-react';
import { useAppSelector, useAppDispatch } from '../../redux';
import { loadReminders, deleteReminder, createReminder } from '../../redux';
import { Icon } from '..';

interface ReminderWidgetProps {
    change: Change;
    className?: string;
    hideRemove?: boolean;
}

function ReminderWidget(props: ReminderWidgetProps) {
    const { change, className } = props;

    const [ reminder, setReminder ] = useState<Reminder|null>(null);

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { reminders, loading, loadingOne } = useAppSelector(state => state.reminders);

    React.useMemo(() => {
        if (reminders?.length > 0)
            setReminder(reminders[0]);
        else
            setReminder(null);
    }, [ reminders?.length ]);

    useEffect(() => {
        dispatch(loadReminders({ change: change._id }));
    }, [change]);

    const add = (offset: string) => {
        dispatch(createReminder({
            change,
            offset,
            project: change.project
        }, {}, () => {

        }));
    };

    const remove = () => {
        if (reminder)
            dispatch(deleteReminder(reminder._id));
    };

    const isLoading = (
        loading === 'pending' ||
        loadingOne === 'pending'
    );

    return (
        <div className={`group ${className || ''} cursor-pointer`}>
            <div className="">
                <Dropdown label="" placement="bottom" dismissOnClick={false} renderTrigger={() => isLoading ? (
                        <Icon type="loading" />
                    ) : (
                        <div className={`cursor-pointer border ${reminder ? ' border-blue-500 text-blue-900 bg-blue-100' : 'text-black bg-white'} hover:bg-gray-200 rounded  py-1 px-2 flex gap-1`}>
                            <Icon color={ !!reminder ? 'red' : 'yellow' } type={isLoading ? 'loading' : 'reminder'} className="inline" size={4} />
                            { reminder && (
                                <span className="text-xs">
                                    {t(`reminders.min_offsets.${reminder.offset}`)}
                                </span>
                            )}
                        </div>
                    )
                }>
                    { !reminder && Reminder.offsets().map((offset: string) => (
                        <Dropdown.Item key={offset} className="font-medium text-xs w-48" onClick={ () => add(offset) }>
                            <Icon type="reminder" className="mr-1.5" size={3} />
                            {t(`reminders.offsets.${offset}`)}
                        </Dropdown.Item>
                    ))}
                    {reminder && (
                        <Dropdown.Item onClick={ remove } className="font-medium text-xs text-red-500">
                            <Icon type="delete" className="mr-1.5" color="red-500" size={3} />
                            {t('reminders.delete_this_one')}
                        </Dropdown.Item>
                    )}
                </Dropdown>
            </div>
        </div>
    );
}

export default ReminderWidget;
