import React from 'react';
import { useTranslation } from 'react-i18next';
import { Draggable } from "react-beautiful-dnd";
import { Icon, UserAvatar, ChangeTrack, Tooltip } from '../../../components';
import { Change } from '../../../models';
import { useAppSelector } from '../../../redux';
import { Badge, Progress } from 'flowbite-react';
import moment from 'moment';

interface CardProps {
    change: Change;
    isSmart?: boolean;
    expanded?: boolean;
    index: number;
    onEdit: (change: Change) => void;
}

const Card = (props: CardProps) => {
    const { t, i18n } = useTranslation();
    const { change, isSmart, expanded, index, onEdit } = props;

    const progression = Math.round(change.progression());

    const { user }   = useAppSelector(state => state.auth);
    const { active } = useAppSelector(state => state.tracks);

    const content = (provided?: AnyObj, snapshot?: AnyObj) => (
        <>
            <li
                className={`select-none mb-1 ${expanded ? 'col-span-1 h-full' : ''}`}
                {...(provided?.draggableProps || {})}
                {...(provided?.dragHandleProps || {})}
                ref={provided?.innerRef}
                onClick={() => onEdit(change)}
            >
                <div className={`${snapshot?.isDragging && !snapshot?.isDropAnimating ? 'rotate-[5deg]' : ''} h-full flex flex-col justify-between border border-gray-300 hover:border-gray-400 rounded-lg cursor-pointer bg-white text-black dark:bg-darkGrey dark:text-white min-h-max`}>
                    <div className="border-b order-gray-100 bg-gray-50 px-2 py-1 flex justify-between items-center rounded-t-lg">
                        <div className="flex space-x-2">
                            <Tooltip content={t("changes.badge.slug")}>
                                <div>
                                    <Badge color={change.color()} className="w-fit text-xs px-1">
                                        <Icon type={change.iconName()} size={3} className="inline" />&nbsp;
                                        {change.slugName()}
                                    </Badge>
                                </div>
                            </Tooltip>
                            {(change.nbComments > 0) && (
                                <Tooltip content={t("changes.badge.comment")}>
                                    <div>
                                        <Badge color="indigo" className="w-fit text-xs px-1">
                                            <Icon type="comment" className="inline" />&nbsp;
                                            {change.nbComments}
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                            {change.issue?.id && (
                                <Tooltip content={t(`changes.badge.issue_triggered_by_${change.issue.source}`)}>
                                    <div>
                                        <Badge color="dander" className="w-fit text-xs px-1">
                                            <Icon type={ change.issueIconName() } className="inline" />&nbsp;
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                            {change.nbAttachments > 0 && (
                                <Tooltip content={t("changes.badge.attachment")}>
                                    <div>
                                        <Badge color="gray" className="w-fit text-xs px-1">
                                            <Icon type="file" className="inline" />&nbsp;
                                            {change.nbAttachments}
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                            {change.developer_notes && (
                                <Tooltip content={t("changes.badge.developer_notes")}>
                                    <div>
                                        <Badge color="indigo" className="w-fit text-xs px-1">
                                            <Icon type="notes" className="inline" />
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        {change.assignee && !change.isTimeTrackable(user) && (
                            <Tooltip content={change.assignee?.firstname + ' ' + change.assignee?.lastname}>
                                <div>
                                    <UserAvatar size={6} user={change.assignee} noTooltip className="my-1" />
                                </div>
                            </Tooltip>
                        )}
                        {change.assignee && change.isTimeTrackable(user) && (
                            <div className="group">
                                <div className="h-8 w-8 flex justify-center items-center">
                                    {active?.change?._id === change._id ? (
                                        <ChangeTrack className="group-hover:hidden" change={change} />
                                    ) : (
                                        <UserAvatar size={6} user={change.assignee} noTooltip className="group-hover:hidden my-1"/>
                                    )}
                                    <ChangeTrack className="hidden group-hover:block" change={change} />
                                </div>
                            </div>
                        )}
                        {!change.assignee && (
                            <div className="w-fit h-[26px]" />
                        )}
                    </div>
                    {progression > 0 && (
                        <div className="p-1 h-4">
                            <Tooltip content={progression + '%'}>
                                <div>
                                    <Progress
                                        progress={progression}
                                        size="sm"
                                        color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    <h4 className="text-sm font-normal heading-md my-1 px-2">
                        {change.title.fr}
                    </h4>
                    <div className="flex justify-between text-sm font-normal p-2 border-t">
                        <Tooltip content={t("changes.badge.priority")}>
                            <div>
                                <Badge color="indigo" className="w-fit" size="xs">
                                    <Icon type="priority" className="inline mr-1" />
                                    {change.priority}
                                </Badge>
                            </div>
                        </Tooltip>
                        {change.quote && (
                            <Tooltip content={t("changes.badge.quote")}>
                                <div>
                                    <Badge color="blue" className="w-fit text-xs px-1">
                                        <Icon type="law" className="inline" />&nbsp;
                                        {change.quote.slug} | {t(`quotes.status_type.${change.quote.status}`)}
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <div className="flex justify-between space-x-1 p-2 py-1 rounded-b-lg border-t">
                        <Tooltip content={t("changes.badge.status")}>
                            <div className="text-xs font-light border border-gray-100 rounded px-2 whitespace-nowrap flex flex-row justify-between">
                                <span className={`whitespace-nowrap text-ellipsis overflow-hidden truncate inline-block ${change.estimate > 0 ? 'max-w-[9rem]' : ''}`}>
                                    <Icon type="progress" size={3} className="inline mr-1"/>&nbsp;
                                    {t(`changes.progress_status_type.${change?.progress?.status}`)}
                                </span>
                            </div>
                        </Tooltip>
                        {(change.estimate > 0) && (
                            <Tooltip content={t("changes.badge.estimated_time")}>
                                <div>
                                    <Badge color="warning" className="w-fit whitespace-nowrap px-1">
                                        <>
                                            <Icon type="estimated" size={3} className="inline mr-1"/>&nbsp;
                                            {change?.estimate} {t('changes.estimate_units_min')}
                                        </>
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    {(change.commits?.length > 0) && (
                        <Tooltip content={t("changes.badge.commit")}>
                            <div className="flex-col justify-center space-y-1 p-2 py-1 rounded-b-lg border-t">
                                {change.commits?.map(commit => (
                                <Badge key={commit.hash} color="success" className="w-fit px-1">
                                    <Icon type="commit" size={3} className="inline"/>&nbsp;
                                    {commit.message}
                                </Badge>
                                ))}
                            </div>
                        </Tooltip>
                    )}
                    {(change.deadline || change.sprints?.length > 0) && (
                        <div className="flex justify-between p-2 rounded-b-lg border-t">
                            {(change.sprints?.length > 0) ? (
                                <Tooltip content={t("changes.badge.sprint")}>
                                    <div>
                                        <Badge color="warning" size="xs" className="w-fit px-1">
                                            <span className="text-xs font-normal">
                                                <Icon type="sprint" color="indigo" className="inline mr-1.5"/>
                                                {change.sprints.slice(-1)[0].name}
                                            </span>
                                        </Badge>
                                    </div>
                                </Tooltip>
                            ) : (
                                <div></div>
                            )}
                            {change.deadline && (
                                <Tooltip content={t("changes.badge.deadline")}>
                                    <div>
                                        <Badge color={change.deadline.isBefore(moment().startOf('day')) ? 'pink' : (change.deadline.isAfter(moment()) ? 'green' : 'indigo')} size="xs" className="w-fit px-1">
                                            <span className="font-normal">
                                                <Icon type="date" color="indigo" className="inline mr-1.5"/>
                                                {change.deadline.locale(i18n.language).format('L')}
                                            </span>
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    {change.release && (
                        <Tooltip content={t("changes.badge.version")}>
                            <div className="flex justify-content-center align-center text-lg p-2 rounded-b-lg border-t">
                                <Badge color="indigo" size="sm" className="flex-1 w-fit flex space-x-3 justify-center px-1">
                                    <Icon type="release" color="indigo" className="inline mr-1.5 -ml-2"/>
                                    {change.release.version}
                                </Badge>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </li>
            {provided?.placeholder}
        </>
    );

    if (expanded || isSmart)
        return content();

    return (
        <Draggable draggableId={change._id} index={index} >
            { content }
        </Draggable>
    )
};

export default Card;
