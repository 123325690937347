import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleField, UserSelectField } from '../../../components';
import { Field } from 'formik';

interface SentryFormProps {};

function SentryForm(props: SentryFormProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-12">
            <div className="flex-1">
                <h2 className="mt-4 mb-1 text-base font-semibold leadin text-lg text-gray-900">
                    {t('settings.sentry')}
                </h2>
                <div className="mt-5">
                    <div className="mt-2 flex space-between">
                        <label htmlFor="enable" className="flex-1 text-sm font-medium leading-6 text-gray-900">
                            {t('settings.enable_sentry')}
                        </label>
                        <ToggleField name="sentry.enabled" className="inline"/>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="user" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.sentry_user')}
                    </label>
                    <div className="mt-2">
                        <UserSelectField name="sentry.user" placeholder={t('settings.sentry_user_placeholder') || ''}/>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="mt-1 flex flex-col lg:mt-0 lg:flex-row lg:flex-wrap lg:space-x-6">
                        <div className="flex-1 w-100">
                            <label htmlFor="host" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('settings.sentry_host')}
                            </label>
                            <div className="mt-2">
                                <Field
                                    type="text"
                                    name="sentry.host"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="https://sentry.io"
                                />
                            </div>
                        </div>
                        <div className="mt-5 lg:mt-0 w-100">
                            <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('settings.sentry_organization')}
                            </label>
                            <div className="mt-2">
                                <Field
                                    type="text"
                                    name="sentry.organization"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="acme"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="client_secret" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.sentry_client_secret')}
                    </label>
                    <small>{t('settings.sentry_client_secret_desc')}</small>
                    <div className="mt-2">
                        <Field
                            type="password"
                            name="sentry.client_secret"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="fakef8db27052bd957f7c520b7263a50c27d1146f3ceb6b4d8a51ec18ddb3e4c"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="api_key" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.sentry_api_key')}
                    </label>
                    <small>{t('settings.sentry_api_key_desc')}</small>
                    <div className="mt-2">
                        <Field
                            type="password"
                            name="sentry.api_key"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="fakef8db27052bd957f7c520b7263a50c27d1146f3ceb6b4d8a51ec18ddb3e4c"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SentryForm;
