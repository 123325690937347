import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Button, ToggleField } from '../../../components';
import type { IconType } from '../../../components';
import { Formik, Form, Field } from 'formik';
import type { FormikValues } from 'formik';
import { Avatar } from 'flowbite-react';
import { RootState, useAppSelector } from '../../../redux';
import { Setting } from '../../../models';
import logo from '../../../assets/images/logo.png';

import AuthForm from './AuthForm';
import OpenAiForm from './OpenAiForm';
import AppriseForm from './AppriseForm';
import TicketingForm from './TicketingForm';
import DocumentationForm from './DocumentationForm';
import GitlabForm from './GitlabForm';
import SentryForm from './SentryForm';
import ClientAreaForm from './ClientAreaForm';
import RemindersForm from './RemindersForm';

interface SettingFormProps {
    isCreation: boolean,
    initialValues: FormikValues,
    onSubmit: (values: AnyObj) => void
}

function SettingForm(props: SettingFormProps) {
    const { isCreation, initialValues, onSubmit } = props;

    const { t }                     = useTranslation();
    const { updating }              = useAppSelector((state: RootState) => state.settings);
    const [activeTab, setActiveTab] = useState(0);

    const validate = (values: AnyObj) => {
        return {};
    }

    const setting = new Setting(initialValues);

    const tabs = [
        ['auth', 'lock'],
        ['openai', 'notes'],
        ['apprise', 'email'],
        ['ticketing', 'ticket'],
        ['documentations', 'comment'],
        ['gitlab', 'commit'],
        ['sentry', 'bug'],
        ['client_area', 'user'],
        ['reminders', 'reminder'],
    ];

    // let's hide the bottom buttons on the Routines tab
    const mustHideBottomButtons = activeTab === 5;

    return (
        <div className="SettingForm bg-white border-b p-4 sm:p-6">
            <main className='md:flex text-center md:text-left'>
                <div id="default-sidebar" className="z-40 md:w-64">
                    <div className="p-0 bg-gray-50 dark:bg-gray-800 border border-gray-100 rounded">
                        <div className="flex justify-center p-2">
                            <Avatar img={ logo } bordered size="lg" />
                        </div>
                        <ul className="space-y-0 font-medium text-gray-600 border-t">
                            {tabs.map(([label, icon], i: number) => (
                                <li key={label} className={`cursor-pointer w-full text-sm py-3 ${(activeTab === i) ? "text-gray-900 bg-gray-200" : "hover:bg-gray-100" }`} onClick={() => setActiveTab(i)}>
                                    <p className="ms-3 flex items-center">
                                        <Icon color={activeTab === i ? 'gray-900' : 'gray-500'} type={icon as IconType} className="inline mr-1" />
                                        {t(`settings.${label}`)}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='w-full px-4 py-4'>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={(values, { setSubmitting }) => {
                            onSubmit(values);
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <div className="space-y-12">
                                    { activeTab === 0 && <AuthForm /> }
                                    { activeTab === 1 && <OpenAiForm /> }
                                    { activeTab === 2 && <AppriseForm /> }
                                    { activeTab === 3 && <TicketingForm /> }
                                    { activeTab === 4 && <DocumentationForm /> }
                                    { activeTab === 5 && <GitlabForm /> }
                                    { activeTab === 6 && <SentryForm /> }
                                    { activeTab === 7 && <ClientAreaForm /> }
                                    { activeTab === 8 && <RemindersForm /> }
                                </div>
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <Button to="/" title={t('common.cancel')} color="navigateBack" icon="back" />
                                    <Button type="submit" title={t('common.save')} color="primary" icon={updating === 'pending' ? 'loading' : 'save'} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </main>
        </div>
    );

}

export default SettingForm;
