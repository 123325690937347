import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleField, UserSelectField } from '../../../components';
import { Field } from 'formik';

interface GitlabFormProps {};

function GitlabForm(props: GitlabFormProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-12">
            <div className="flex-1">
                <h2 className="mt-4 mb-1 text-base font-semibold leadin text-lg text-gray-900">
                    {t('settings.gitlab')}
                </h2>
                <div className="mt-5">
                    <div className="mt-2 flex space-between">
                        <label htmlFor="enable" className="flex-1 text-sm font-medium leading-6 text-gray-900">
                            {t('settings.enable_gitlab')}
                        </label>
                        <ToggleField name="gitlab.enabled" className="inline"/>
                    </div>
                </div>
                <div className="mt-5">
                    <label htmlFor="user" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('settings.gitlab_user')}
                    </label>
                    <div className="mt-2">
                        <UserSelectField name="gitlab.user" placeholder={t('settings.gitlab_user_placeholder') || ''}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GitlabForm;
